// import rails-ujs
import Rails from '@rails/ujs'

Rails.start()

// import active storage
import * as ActiveStorage from '@rails/activestorage'

ActiveStorage.start()

// import all channels.
import 'channels'

// import bootstrap
import 'bootstrap'
import './plugins/bootstrap-notify.min'
import { Modal } from 'bootstrap'

// import other library
import Dropzone from 'dropzone'
// dropzone settings
Dropzone.autoDiscover = false

import Moment from 'moment'
import JsZip from 'jszip'
import FileSaver from 'file-saver'

// import custom plugins
import Cookies from './plugins/cookie'
import Utils from './modules/utils'
import Dashboard from './modules/dashboard'
import Time from './plugins/time'

// global initialization
global.$ = jQuery
global.Modal = Modal
global.Cookies = Cookies
global.Utils = Utils
global.JsZip = JsZip
global.FileSaver = FileSaver
global.Moment = Moment

$(document).ready(function () {
  Utils.init()
  Dashboard.init()
  Time.init()
})
