const Dashboard = {
  init: () => {
    var iframe = document.getElementById("customer_dashboard");
    if (iframe) {
      // Adjusting the iframe height onload event
      iframe.onload = function(){
        iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
      }
    }
    
  }
}

export default Dashboard;