// Moment format
// YYYY: 4-digit year '2019'
// YY: 2-digit year '19'
// MMMM: Full-length month 'June'
// MMM: 3 character month 'Jun'
// MM: Month of the year, zero-padded '06'
// M: Month of the year '6'
// DD: Day of the month, zero-padded '01'
// D: Day of the month '1'
// Do: Day of the month with numeric ordinal contraction '1st'
// HH: hour of day from 0-24, zero-padded, '14'
// H: hour of day from 0-24, '14'
// hh: hour of day on 12-hour clock, zero-padded, '02'
// h: hour of the day on 12 hour clock, '2'
// mm: minute, zero-padded, '04'
// m: minute, '4'
// ss: second, zero-padded
// s: second
// A: 'AM' or 'PM'
// a: 'am' or 'pm'

const Time = {
  init: () => {
    $('[local_time="true"]').each(function(id, el){
      var time = $(el).attr("utc_time");
      var format = $(el).attr("format");

      if(format) {
        $(el).html(Moment(time).format(format))
      } else {
        $(el).html(Moment(time).format('MMM Do YYYY, h:mm:ss a'))
      }
    })
  }
}

export default Time;
