import JsZip from 'jszip'
import FileSaver from 'file-saver'
import { Tooltip } from 'bootstrap'

const PDFMerger = require('pdf-merger-js')

const Utils = {
  init: () => {
    $('.shared-side-bar-container .collapse-btn').on('click', function () {
      $('.shared-side-bar-container').toggleClass('mini')
      $('body').toggleClass('mini-sidebar')
    })

    // init the bootstrap tooltips
    let tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]'),
    )
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl)
    })

    // $("#order-view-setting .view-setting-btn").on('click', function (e) {
    //   // console.log("stopPropagation called")
    //   // e.stopPropagation();
    //   $("#order-view-setting .dropdown-menu").dropdown('toggle')
    // });

    // $('#myDropdown').on('show.bs.dropdown', function () {
    //   e.stopPropagation();
    // })
  },

  // for reset the select single & all on orders table
  resetOrderTableCheckboxes: () => {
    const user_id = $('body').attr('data-user')

    window.localStorage.setItem('order_is_all', 'not_checked')
    window.localStorage.removeItem(`order_ids_${user_id}`)
  },

  // for handling the per page feature
  set_will_paginate_per_page: (e) => {
    let per_page = $(e).data('per-page')
    let currentUrl = new URL(window.location.href)

    currentUrl.searchParams.set('page', '1')
    currentUrl.searchParams.set('per_page', per_page)

    Utils.resetOrderTableCheckboxes()
    window.location = currentUrl.href
  },

  // for handling the paginated page feature
  set_will_paginate_page: (e) => {
    let page = $(e).data('page')
    let currentUrl = new URL(window.location.href)

    currentUrl.searchParams.set('page', page)

    Utils.resetOrderTableCheckboxes()
    window.location = currentUrl.href
  },

  // https://grotesquegentleadvance--samkhaled.repl.co/
  openNotification: (message = '', type = 'success', options = {}) => {
    if ($('body #popup').length) {
      popup.hide()
    }

    $.notify(message, {
      type: type,
      animate: {
        enter: `animated ${options?.effect_enter || 'slideInRight'}`,
        exit: `animated ${options?.effect_exit || 'slideOutRight'}`,
      },
      placement: {
        from: 'bottom',
      },
      timer: 200,
      delay: options?.manualHide ? 0 : 7500,
      mouse_over: 'pause',
    })
  },
  downloadBlob: (url) => {
    return fetch(url).then((resp) => resp.blob())
  },
  downloadAndSave: async (fileName, url) => {
    return FileSaver.saveAs(url, fileName)
  },
  downloadAndMergeMulti: async (labels) => {
    let merger = new PDFMerger()

    await (async () => {
      for (const label of labels) {
        await merger.add(label.labelUrl)
        // Utils.openNotification(label.orderId + '.pdf is merged successfully!')
      }
      const mergedPdfBlob = await merger.saveAsBlob()
      return FileSaver.saveAs(mergedPdfBlob, 'labels.pdf')
    })()
  },
  downloadAndZipMultiOrder: async (labels) => {
    const blobs = []

    for (const label of labels) {
      const blob = await Utils.downloadBlob(label.labelUrl)
      Utils.openNotification(label.orderId + '.pdf is downloaded successfully!')

      blobs.push({ blob: blob, key: label.orderId })
    }

    return Utils.exportZipMultiOrder(blobs)
  },
  exportZipMultiOrder: (blobs) => {
    const zip = JsZip()

    for (const blob of blobs) {
      let parentFolder = zip.folder(`labels`)
      let folder = parentFolder.folder(blob.key)
      folder.file(`${blob.key}.pdf`, blob.blob)
    }

    zip.generateAsync({ type: 'blob' }).then((zipFile) => {
      let fileName = `labels.zip`
      return FileSaver.saveAs(zipFile, fileName)
    })
  },
  removeElementFromArray: (array, el) => {
    let index = array.indexOf(el)

    if (index > -1) {
      array.splice(index, 1)
    }

    return array
  },
}

export default Utils
